[data-reach-menu-button][aria-expanded='true'] {
  color: var(--primary);
}

[data-reach-menu-item] {
  padding: 0.5rem 1rem;
}

.active[data-reach-menu-item][data-selected] {
  color: #ffff !important;
}
