[data-reach-popover] {
  z-index: 99;
}

a[data-reach-menu-item] {
  background: white;
  color: black;
  display: flex;
  padding: 0.5rem 1rem;
}

[data-reach-menu-item][data-selected] {
  background: var(--primary) !important;
  color: white;
}
