#root,
body {
  min-height: 100vh;
}

.active {
  color: var(--primary) !important;
  font-weight: bold !important;
  text-decoration: underline !important;
}

.active svg {
  box-shadow: 0 3px var(--primary);
  color: var(--primary) !important;
}

.active .drop-down-chevron {
  box-shadow: none;
}

button:focus,
a:focus,
nav > a:focus,
nav > div > a:focus,
li:focus,
input:focus,
button:focus {
  box-shadow: 0 0 0 1px var(--primary), 0 0 0 1px #fff, 0 0 0 4px var(--magenta_light) !important;
  outline: var(--primary) thin 1px !important;
}

[data-reach-dialog-overlay] {
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-reach-dialog-content] {
  max-height: 80vh;
}

@media (min-width: 768px) {
  [data-reach-dialog-content] {
    max-height: 99vh;
    margin: 1vh 0 0 0;
  }
}
