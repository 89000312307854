.ql-picker.ql-font .ql-picker-item::before {
  font-size: 12px;
}

.ql-picker.ql-font .ql-picker-label::before {
  font-size: 12px;
}

.ql-picker.ql-font .ql-active::before {
  font-size: 12px;
}

.ql-picker.ql-size .ql-picker-label {
  width: 65%;
  font-size: 0;
}

.ql-picker.ql-size .ql-picker-label::before {
    content: attr(data-value) !important;
    font-size: 14px;
}

.ql-picker.ql-size .ql-active::before {
    content: attr(data-value) !important;
    font-size: 14px;
}

/* Set content for font-families */
.ql-font-teleNeoWeb,
.ql-font span[data-value='teleNeoWeb']::before {
  font-family: TeleNeoWeb, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value='arial']::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value='comic-sans']::before {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value='courier-new']::before {
  font-family: 'Courier New';
}
.ql-font-georgia,
.ql-font span[data-value='georgia']::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value='helvetica']::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value='lucida']::before {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9px']::before {
  content: '9';
  font-size: 9px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='10px']::before {
  content: '10';
  font-size: 10px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='11px']::before {
  content: '11';
  font-size: 11px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='12px']::before {
  content: '12';
  font-size: 12px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='14px']::before {
  content: '14';
  font-size: 14px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='16px']::before {
  content: '16';
  font-size: 16px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='18px']::before {
  content: '18';
  font-size: 18px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='20px']::before {
  content: '20';
  font-size: 20px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='22px']::before {
  content: '22';
  font-size: 22px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='24px']::before {
  content: '24';
  font-size: 24px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='26px']::before {
  content: '26';
  font-size: 26px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='28px']::before {
  content: '28';
  font-size: 28px !important;
}

.ql-size-9px {
  font-size: 9px;
}
.ql-size-10px {
  font-size: 10px;
}
.ql-size-11px {
  font-size: 11px;
}
.ql-size-12px {
  font-size: 12px;
}
.ql-size-14px {
  font-size: 14px;
}
.ql-size-16px {
  font-size: 16px;
}
.ql-size-18px {
  font-size: 18px;
}
.ql-size-20px {
  font-size: 20px;
}
.ql-size-22px {
  font-size: 22px;
}
.ql-size-24px {
  font-size: 24px;
}
.ql-size-26px {
  font-size: 26px;
}
.ql-size-28px {
  font-size: 28px;
}
